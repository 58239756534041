import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import Content from '../Content';
import FieldSwitcher from './FieldSwitcher';

import { FormContextProvider } from './formUtils';
import { media } from '../theme';

const Form = props => {
  const { title, description, fields, submitText, successMessage } = props;
  const { handleSubmit, register, errors, control } = useForm();
  const [success, setSuccess] = useState(false);
  const formRef = useRef();

  const onSubmit = data => {
    // console.log(data);
    setSuccess(true);
    window.scroll({
      // top: formRef.current.scrollTop,
      behavior: 'smooth',
    });
  };

  return (
    <FormContextProvider value={{ register, errors, control }}>
      <FormWrapper
        className="form"
        onSubmit={handleSubmit(onSubmit)}
        data-netlify="true"
        netlify-honeypot="honeypotField"
        name={title}
        ref={formRef}
      >
        <h2 className="form__title">{title}</h2>
        <Content className="form__description">{description}</Content>
        {success ? (
          <p style={{ color: 'green' }}>{successMessage}</p>
        ) : (
          <fieldset className="form__fields">
            <div className="fieldset-flex-fix">
              {fields.map(field => (
                <FieldSwitcher key={field._key} field={field} />
              ))}
              <label className="honeypot">
                Don't fill this out if you're human:{' '}
                <input name="honeypotField" />
              </label>
              <input
                type="submit"
                className="form__submit"
                value={submitText || 'Submit'}
              />
            </div>
          </fieldset>
        )}
      </FormWrapper>
    </FormContextProvider>
  );
};

const FormWrapper = styled.form`
  fieldset {
    border: 0;
    padding: 0;
  }
  @supports (display: grid) {
    .fieldset-flex-fix {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1rem;
      grid-gap: 1rem;
    }
  }
  .form {
    &__submit {
      justify-self: start;
      -webkit-appearance: none;
      border-radius: 0px;
      grid-column: span 2;
      margin-right: 1rem;
      background: ${({ theme }) => theme.black};
      color: white;
      padding: 0.75rem 1.5rem;
      border: 0;
      text-transform: uppercase;
      font-family: ${({ theme }) => theme.font.mono};
    }
  }
  .field-address {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }
  .complex-field-label {
    /* font-family: synthese, sans-serif; */
    /* font-family: ${({ theme }) => theme.font.heading}; */
    font-size: 1.8rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 2px;
    line-height: 2.15;
    margin: 0 0 0.5rem;
    /* font-size: 1.4rem; */
    text-transform: uppercase;
    display: block;
    grid-column: span 2;
    width: 50%;
    ::after {
      content: '';
      width: 100%;
      height: 1px;
      background: ${({ theme }) => theme.black};
      display: block;
    }
  }
  .honeypot {
    display: none;
  }
`;

export default Form;
