import React from 'react';

import Label, { FieldWrapper } from './Label';
import ErrorMessage from './ErrorMessage';
import { useFocusState, useFormContext } from './formUtils';

const TextField = ({ name, fieldOptions, _type }) => {
  const required =
    fieldOptions && fieldOptions.required ? fieldOptions.required : false;
  const halfWidth =
    fieldOptions && fieldOptions.halfWidth ? fieldOptions.halfWidth : false;
  const description =
    fieldOptions && fieldOptions.description ? fieldOptions.description : false;
  const adminLabel =
    fieldOptions && fieldOptions.adminLabel ? fieldOptions.adminLabel : false;
  const fieldName = adminLabel ? adminLabel : name;

  const { focused, handleFocus, handleBlur } = useFocusState();
  const { register, errors } = useFormContext();
  const error = errors[fieldName];

  return (
    <FieldWrapper halfWidth={halfWidth} className="text-field-wrapper">
      <Label
        className="field-text"
        isFocused={focused}
        htmlFor={fieldName}
        halfWidth={halfWidth}
      >
        <span className="label-text">
          {name}
          {required && '*'}
        </span>
        <input
          className="text-input"
          type={_type === 'emailField' ? 'email' : 'text'}
          name={fieldName}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={register({ required })}
        />
      </Label>
      {description && <p className="field-description">{description}</p>}
      {error && <ErrorMessage className="error--text-field" {...error} />}
    </FieldWrapper>
  );
};

export default TextField;
