import React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import classNames from 'classnames';
import ReactPlayer from 'react-player';

import Form from './Form';

const Content = ({ children, styles, className }) => {
  const serializers = {
    types: {
      video: ({ node }) => {
        const { url } = node;
        return (
          <VideoContainer className="video-container">
            <ReactPlayer className="content-video" url={url} controls />
          </VideoContainer>
        );
      },
      form: props => {
        const { node } = props;
        console.log(props);
        return <Form {...node} />;
      },
      fileButton: ({ node }) => {
        // console.log(node);
        const { buttonText, file } = node;
        return (
          <Button
            className="content-file-button"
            href={`${file.asset.url}?dl=`}
          >
            {buttonText || 'Download'}
          </Button>
        );
      },
    },
  };
  return (
    <ContentWrapper className={classNames('content-wrapper', className)}>
      <BlockContent blocks={children} serializers={serializers} />
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 800px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  .content-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
`;

const Button = styled.a`
  display: inline-block;
  background: ${({ theme }) => theme.black};
  color: white;
  text-decoration: none;
  font-family: ${({ theme }) => theme.font.mono};
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  text-align: center;
`;

export default Content;
